import React from 'react';
import { Layout, Flex } from 'antd';
import Search from './Search'
import StudentList from './StudentList'

const { Header, Footer, Sider, Content } = Layout;

const Lecturer = () => {
    const contentStyle = {
        textAlign: 'center',
        minHeight: 120,
        lineHeight: '120px',
        color: '#fff',
    };
    const siderStyle = {
        textAlign: 'left',
        lineHeight: '120px',
    };

    return (
        <>
            <div className='lecturer'>
                <div className='search_div'>
                    <Search />
                </div>
                <StudentList />
            </div>
        </>
    )
}

export default Lecturer