import React from 'react';
import { redirect, useHistory } from "react-router-dom";
import { Button, Checkbox, Form, Input, Divider } from 'antd';
import { SearchOutlined, FileSearchOutline } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { setSearchRequest } from '../redux/StudentTalentPagingSlice'

const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const Search = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const onSearch = () => {
        const fieldsValue = form.getFieldsValue()
        const email = fieldsValue.email
        //console.log('onSearch form', form, email)
        //dispatch(setSearchEmail(email))
        dispatch(setSearchRequest({email: fieldsValue.email, student_surname: fieldsValue.student_surname}))
    }

    return (
        <Form form={form}
        name="basic" 
        size="small"
        layout="vertical"
        labelCol={{
             span: 24,
        }}
        // wrapperCol={{
        //     span: 16,
        // }}
        style={{
            maxWidth: 600,
        }}
        initialValues={{
            remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 14,
                }}
            >
                <Button key="register" type="primary" htmlType="button" onClick={() => history.push('/register')}>
                    New registration
                </Button>
                <Divider orientation="left"></Divider>
            </Form.Item>
            <Form.Item label="Surname" name="student_surname">
                <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" >
                <Input />
            </Form.Item>
            {
                process.env.NODE_ENV == 'development'
                &&
                <div>
                        <Form.Item label="Course name" name="course_name" >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Registration person" name='registration_person'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Support for students" name="support_for_studenst" >
                            <Input />
                        </Form.Item>
                </div>
            }

            <Form.Item
                wrapperCol={{
                    offset: 2,
                    span: 14,
                }}
            >
                <Button key="search" type="primary" icon={<SearchOutlined />} onClick={(event) => onSearch()}>
                    Search
                </Button>
            </Form.Item>


                   {/* 
        


         */}
    </Form>
)};
export default Search;
