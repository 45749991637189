import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Pagination, Card, DatePicker, Space, Popconfirm, message } from 'antd';
//import testPageJson from './../_test_data/sth_page_1.json'
//import { deepTechArea, deepTechAreaValue, supportForStudents, supportForStudentsValue } from './Dictionary'
import { useGetUsersSearchQuery, setSearchRequest, useDeleteUserMutation } from '../../redux/UserApi'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash';

const UserList = () => {
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
        field: 'email',
        order: 'ascend'
    });
    const tableParamsToSort = () => {
        const result = _.camelCase(tableParams.field) + (tableParams.order === 'descend' ? ',desc' : '')
        console.log('tableParamsToSort', result)
        return (result)
    }
    const dispatch = useDispatch()
    const page = useSelector((state) => state.userPaging.page)
    const totalCount = useSelector((state) => state.userPaging.totalCount)
    console.log('UserList totalCount', totalCount)
    const searchRequest = useSelector((state) => state.userPaging.searchRequest)
    console.log('UserList searchRequest', searchRequest)
    const userSearch = useGetUsersSearchQuery({ searchRequest: searchRequest, page: page, size: 30, sort: tableParamsToSort() }) //tableParamsToSort()
    const userList = _.get(userSearch, 'data.content', [])
    const { error, isLoading } = userSearch
    const [deleteUser, deleteUserResult] = useDeleteUserMutation()
    //const error=null, isLoading=false
    let dataSource = []
    dataSource.push(...userList)
    //dataSource.push(...testPageJson.content)

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        console.log('handleTableChange filters', filters)
        const filter_deeptech_area = Array.isArray(filters.deeptech_area) ? filters.deeptech_area.join('|') : ''
        const filter_support_for_student = Array.isArray(filters.support_for_student) ? filters.support_for_student.join('|') : ''
        dispatch(setSearchRequest({ deeptech_area: filter_deeptech_area, support_for_student: filter_support_for_student }))

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            //setData([]);
        }
    };

    const onDelete = (record) => {
        deleteUser(record.id)
    }
    const cancel = (e) => {
        console.log(e);
        message.info('Click on No');
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
        },
        {
            title: 'Surname',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            render: (text, record) => {
                return (<a href={'/user/' + record.id}>{text}</a>)
            },
        },
        {
            title: 'Registered by',
            dataIndex: 'createdBy',
            key: 'createdBy',
            sorter: true,
        },
        {
            title: 'Registration date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: true,
            render: (timestamp) => (timestamp ?
                <>
                    <span className='nowrap'>{timestamp.substring(0, 10)}</span> <span className='nowrap'>{timestamp.substring(11, 19)}</span>
                </>
                : ''),
        },
        {
            title: 'Authorities',
            dataIndex: 'authorities',
            key: 'authorities',
            sorter: false,
            render: (authorities) => (Array.isArray(authorities) ?
                <>
                    <span className='nowrap'>{authorities.reduce((a, c) => a + ' ' + c.name, '')}</span>
                </>
                : ''),
        },
        {
            title: 'Action',
            key: 'action',
            sorter: true,
            render: (_, record) => (
                <Space size="middle">
                    <a href={'/user/' + record.id}>Edit</a>
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user?"
                        onConfirm={() => onDelete(record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type='text'>Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
        {
            title: 'Updated',
            dataIndex: 'updateTimestamp',
            key: 'updateTimestamp',
            //sorter: true,
            render: (timestamp) => (timestamp ?
                <>
                    <span className='nowrap'>{timestamp.substring(0, 10)}</span> <span className='nowrap'>{timestamp.substring(11, 19)}</span>
                </>
                : ''),
            hidden: process.env.NODE_ENV !== 'development'
        },
    ];

    return (
        <div className=''>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
                ) : dataSource ? (
                <div className='projectList'>
                            <Table dataSource={dataSource} columns={columns} bordered onChange={handleTableChange}
                                pagination={{
                                    position: ["bottomCenter"],
                                    pageSize: 20,
                                    total: totalCount,
                                }}
                                >
                                <Pagination pageSize={2} total={totalCount} showTotal={((total, range)=> ''+total)} />
                                    </Table>
                </div>
            ) : null}
        </div>
    )
}

export default UserList
