import './App.css';
import packageJson from './../package.json';
import preval from 'preval.macro';
import AppRouter from './AppRouter'
import { Provider } from 'react-redux'
import { store } from './redux/store'

function App() {
  return (
    <Provider store={store}>
    <div className="App" data_build_version={packageJson.version} data_build_time={preval`module.exports = new Date().toISOString();`}>
      <AppRouter />
    </div>
    </Provider>
  );
}

export default App;
