import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash';
import { authHeader } from './auth'
import { setTotalCount } from './StudentTalentPagingSlice'

export const studentTalentRegistrationApi = createApi({
    reducerPath: 'studentTalentRegistrationApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/rest/student-talent-registration/' }),
    endpoints: (builder) => ({
        getStudentsTalent: builder.query({
            query: (id) => `${id}`,
        }),
        getStudentsTalentSearch: builder.query({
            query: ({ page = -1, size = 20, sort = 'updateTimestamp,desc', searchRequest }) => {
                return({
                url: `search?page=${page}&sort=${sort}&size=${size}`,
                method: 'POST',
                headers: authHeader(),
                body: searchRequest,
            })},
            onCacheEntryAdded(id, params) {
                const { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry,
                    updateCachedData } = params
                // console.log('onCacheEntryAdded params', params)
                cacheDataLoaded.then(() => {
                    const cacheEntry = getCacheEntry()
                    const totalElements = _.get(cacheEntry, 'data.totalElements', -1)
                    dispatch(setTotalCount(totalElements))
                    // console.log('onCacheEntryAdded totalElements', totalElements)
                    // console.log('onCacheEntryAdded getCacheEntry', cacheEntry)
                })
            },
        }),

    }),
})

export default studentTalentRegistrationApi
export const { useGetStudentsTalentSearchQuery, useGetStudentsTalentQuery } = studentTalentRegistrationApi
