import React, { useState, useEffect } from 'react'
import { Router, Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useGetUserLoginQuery, setLoggedUser } from './redux/UserApi'
import HomePage from './sth/HomePage'
import MainContent from './sth/MainContent'
import StudentList from './sth/StudentList'
import Search from './sth/Search'
import Lecturer from './sth/Lecturer'
import Register, { EditStudentTalent } from './sth/Register'
import {PrivateRoute} from './components/PrivateRoute'
import UserRoute from './components/user/UserRoute'
import PracownicyRoute from './components/pracownicy/PracownicyRoute';
import { userService } from './components/user/user.service.js';
import _ from 'lodash';

const AppRouter = (props) => {
    const dispatch = useDispatch()
    const loggedUser = userService.loggedUser()
    const loggedUserRdx = useSelector((state) => _.get(state, 'loggedUser.loggedUser', null), shallowEqual)
    //const [loggedUser, setLoggedUser] = useState(loggedUserRdx);
    //useEffect(() => { setLoggedUser(loggedUserRdx) }, [loggedUserRdx])
    //useEffect(() => { dispatch(setLoggedUser(loggedUser)) }, [loggedUser])
    console.log('AppRouter loggedUserRdx', loggedUserRdx)
    return (
        <Switch>
            <Route path="/" exact component={HomePage}></Route>
            <Route path="/students"><MainContent><StudentList /></MainContent></Route>
            <Route path="/search"><MainContent><Search /></MainContent></Route>
            <Route path="/edit/:idRegistration" render={props =>
                <MainContent><EditStudentTalent {...props} /></MainContent>
            } />
            <Route path="/lecturer">
                <PrivateRoute loggedUser={loggedUserRdx} setLoggedUser={setLoggedUser}>
                    <MainContent setLoggedUser={setLoggedUser}><Lecturer /></MainContent>
                </PrivateRoute>
            </Route>
            <Route path="/register">
                <PrivateRoute>
                    <MainContent setLoggedUser={setLoggedUser}><Register /></MainContent>
                </PrivateRoute>
            </Route>
            <Route path="/user">
                {/* <PrivateRoute setLoggedUser={setLoggedUser} > */}
                <PrivateRoute>
                    <UserRoute />
                </PrivateRoute>
                {/* </PrivateRoute> */}
            </Route>
            <Route path="/pracownicy">
                {/* <PrivateRoute setLoggedUser={setLoggedUser} > */}
                <PrivateRoute>
                    <PracownicyRoute />
                </PrivateRoute>
                {/* </PrivateRoute> */}
            </Route>
        </Switch>
    ) 
}

export default withRouter(AppRouter)