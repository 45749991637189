
export const deepTechAreaValue = (code) => {
    const value = deepTechArea.find(el => el.value === code)
    return value ? value.label : code

    // switch (code) {
    //     case 'darea_001': return 'Advanced Computing / Quantum Computing';
    //     case 'darea_002': return 'Advanced Manufacturing';
    //     case 'darea_003': return 'Advanced Materials';
    //     case 'darea_004': return 'Aerospace, Automotive and Remote Sensing';
    //     case 'darea_005': return 'Artificial Intelligence and Machine Learning, including Big Data';
    //     case 'darea_006': return 'Biotechnology and Life Sciences';
    //     case 'darea_007': return 'Communications and Networks, including 5G';
    //     case 'darea_008': return 'Cybersecurity and Data Protection';
    //     case 'darea_009': return 'Electronics and Photonics';
    //     case 'darea_010': return 'Internet of Things, W3C, Semantic Web';
    //     case 'darea_011': return 'Robotics';
    //     case 'darea_012': return 'Semiconductors(microchips)';
    //     case 'darea_013': return 'Sustainable Energy and Clean Technologies';
    //     case 'darea_014': return 'Virtual Reality, Augmented Reality, Metaverse';
    //     case 'darea_015': return 'Web 3.0, including Blockchain, Distributed Ledgers, NFTs';
    //     default: return code;
    // }
}

export const deepTechArea = [
    { label: "Advanced Computing / Quantum Computing", value: "darea_001" },
    { label: "Advanced Manufacturing", value: "darea_002" },
    { label: "Advanced Materials", value: "darea_003" },
    { label: "Aerospace, Automotive and Remote Sensing", value: "darea_004" },
    { label: "Artificial Intelligence and Machine Learning, including Big Data", value: "darea_005" },
    { label: "Biotechnology and Life Sciences", value: "darea_006" },
    { label: "Communications and Networks, including 5G", value: "darea_007" },
    { label: "Cybersecurity and Data Protection", value: "darea_008" },
    { label: "Electronics and Photonics", value: "darea_009" },
    { label: "Internet of Things, W3C, Semantic Web", value: "darea_010" },
    { label: "Robotics", value: "darea_011" },
    { label: "Semiconductors (microchips)", value: "darea_012" },
    { label: "Sustainable Energy and Clean Technologies", value: "darea_013" },
    { label: "Virtual Reality, Augmented Reality, Metaverse", value: "darea_014" },
    { label: "Web 3.0, including Blockchain, Distributed Ledgers, NFTs", value: "darea_015" },
]

export const supportForStudents = [
    { label: "Business-oriented", value: "BO"},
    { label: "Mentoring/tutoring", value: "MT" },
    { label: "Potential PhD candidate", value: "PhD" },
    { label: "Career counseling", value: "CC" },
]

export const supportForStudentsValue = (code) => {
    const value = supportForStudents.find(el => el.value === code)
    return value ? value.label : code
}