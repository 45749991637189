import React from 'react';
import { redirect, useHistory } from "react-router-dom";
import { Button, Checkbox, Form, Input, Select } from 'antd';
import {DataProvider} from './DataProvider'
import { supportForStudents, deepTechArea } from './Dictionary'
import { useGetStudentsTalentQuery } from '../redux/StudentTalentRegistrationApi'
import _ from 'lodash';

const { TextArea } = Input;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};


const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const Register = (props) => {
    const history = useHistory();
    //const studentId = props.match.params.idRegistration
    const studentId = _.get(props, 'match.params.idRegistration', 0)
    console.log('Register studentId', studentId)
    const studentsTalent = useGetStudentsTalentQuery(studentId)
    const data = {}

    const onFinish = (values) => {
        const toSend = values
        toSend.support_for_student = JSON.stringify(toSend.support_for_student)
        console.log('To send:', toSend);
        DataProvider.sendToServer(toSend,
            fromServer => {
                console.log('fromServer:', fromServer);
                history.push('/lecturer')
            }, error => {
                console.log('server error', error)
            })
    };
    return (
    <div className='register'>
            <EditForm data={data} onFinish={onFinish} />
    </div>
)};
export const EditStudentTalent = (props) => {
    const history = useHistory();
    const studentId = _.get(props, 'match.params.idRegistration', 0)
    console.log('Register studentId', studentId)
    const studentsTalent = useGetStudentsTalentQuery(studentId)
    const { data, error, isLoading } = studentsTalent
    console.log('Register data', data)
    const formData = {...data}
    if (formData && formData.support_for_student) {
        formData.support_for_student = JSON.parse(formData.support_for_student)
    }
    console.log('Register error', error)

    const onFinish = (values) => {
        const toSend = { id: studentId, ...values }
        toSend.support_for_student = JSON.stringify(toSend.support_for_student)
        console.log('To send:', toSend);
        DataProvider.sendToServer(toSend,
            fromServer => {
                console.log('fromServer:', fromServer);
                history.push('/lecturer')
            }, error => {
                console.log('server error', error)
            })
    };
    return (
        <div className='register'>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
                ) : formData ? (<EditForm data={formData} onFinish={onFinish} />
            ) : null}
        </div>
    )
};

const EditForm = ({ data, onFinish }) => {
    return(
        <Form name="basic" layout="horizontal" labelCol={{ span: 8, }}
            wrapperCol={{ span: 12, }}
            style={{ width: 1000, }}
            initialValues={{ ...data, remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className='register-title'>Register new student talent</div>
            <Form.Item label="Name" name="student_name" >
                <Input />
            </Form.Item>
            <Form.Item label="Surname" name="student_surname"
                rules={[{ message: 'Please input student surname!', },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="Email" name="email"
                rules={[{ required: true, message: 'Please input student email!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Course name" name="course_name">
                <Input />
            </Form.Item>
            <Form.Item label="Field of study" name='field_of_study'>
                <Input />
            </Form.Item>
            <Form.Item label="Deeptech area" name='deeptech_area'>
                <Select
                    options={deepTechArea}
                />
            </Form.Item>
            <Form.Item label="Support for student" name="support_for_student" >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={handleChange}
                    options={supportForStudents}
                />
            </Form.Item>

            <Form.Item label="Comment" name="comment">
                <TextArea rows={4} placeholder="maxLength is 2000" maxLength={2000} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 2, span: 14, }}>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button type="link" href="/lecturer" htmlType="button">
                    Cancel
                </Button>
            </Form.Item>

        </Form>
    )
}
export default Register;
