import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Pagination, Card, DatePicker, version } from 'antd';
import testPageJson from './../_test_data/sth_page_1.json'
import { deepTechArea, deepTechAreaValue, supportForStudents, supportForStudentsValue } from './Dictionary'
import {useGetStudentsTalentSearchQuery} from '../redux/StudentTalentRegistrationApi'
import { useSelector, useDispatch } from 'react-redux'
import { setSearchRequest } from '../redux/StudentTalentPagingSlice'
import { useGetPracownicyPwrSearchQuery } from '../redux/PracownicyPwrApi'
import _ from 'lodash';

const StudentList = () => {
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
        field: 'updateTimestamp',
        order: 'descend'
    });
    const tableParamsToSort = () => {
        const result = _.camelCase(tableParams.field) + (tableParams.order === 'descend' ? ',desc' : '')
        console.log('tableParamsToSort', result)
        return (result)
    }
    const dispatch = useDispatch()
    const page = useSelector((state) => state.studentsPaging.page)
    const totalCount = useSelector((state) => state.studentsPaging.totalCount)
    //console.log('totalCount', totalCount)
    const searchRequest = useSelector((state) => state.studentsPaging.searchRequest)
    console.log('StudentList searchRequest', searchRequest)
    const studentsTalentSearch = useGetStudentsTalentSearchQuery({ searchRequest: searchRequest, page: page, size: 30, sort: tableParamsToSort() })
    //const pracownicyPageSearch = useGetPracownicyPwrSearchQuery({ page:0, sort: 'createTimestamp,desc', searchRequest })
    const studentList = _.get(studentsTalentSearch, 'data.content', [])
    const { error, isLoading } = studentsTalentSearch
    let dataSource = []
    dataSource.push(...studentList)
    //dataSource.push(...testPageJson.content)

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        console.log('handleTableChange filters', filters)
        const filter_deeptech_area = Array.isArray(filters.deeptech_area) ? filters.deeptech_area.join('|') : ''
        const filter_support_for_student = Array.isArray(filters.support_for_student) ? filters.support_for_student.join('|') : ''
        dispatch(setSearchRequest({ deeptech_area: filter_deeptech_area, support_for_student: filter_support_for_student }))

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            //setData([]);
        }
    };

    return (
        <div className=''>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
                ) : studentList ? (
                <div className='projectList'>
                            <Table dataSource={dataSource} columns={columns} bordered onChange={handleTableChange}
                                pagination={{
                                    position: ["bottomCenter"],
                                }}
                                >
                                <Pagination pageSize={2} total={totalCount} showTotal={((total, range)=> 'aa'+total)} />
                                    </Table>
                </div>
            ) : null}
        </div>
    )
}

export default StudentList

const ColumnSupportForStudent = ({ support_for_student }) => {
    const tags = JSON.parse(support_for_student)
    if (!Array.isArray(tags)) return(<></>)
    return(
        <>
            {tags.map((tag) => (
                <Tag color="blue" key={tag}>
                    {supportForStudentsValue(tag)}
                </Tag>
            ))}
        </>
    )
}

const columns = [
    {
        title: 'Name',
        dataIndex: 'student_name',
        key: 'name',
        sorter: true,
    },
    {
        title: 'Surname',
        dataIndex: 'student_surname',
        key: 'surname',
        sorter: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (text, record) => {
            return (<a href={'/edit/' + record.id}>{text}</a>)
        },
    },
    {
        title: 'Course name',
        dataIndex: 'course_name',
        key: 'course_name',
        sorter: true,
    },
    {
        title: 'Field of study',
        dataIndex: 'field_of_study',
        key: 'field_of_study',
        sorter: true,
    },
    {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        sorter: true,
    },
    {
        title: 'Deep tech area',
        dataIndex: 'deeptech_area',
        key: 'deeptech_area',
        render: (code) => deepTechAreaValue(code),
        filters: deepTechArea.map(item => {return({'text': item.label, value: item.value})})
    },
    {
        title: 'Registered by',
        dataIndex: 'registration_person',
        key: 'registration_person',
        sorter: true,
    },
    {
        title: 'Registration date',
        dataIndex: 'registration_date',
        key: 'registration_date',
        sorter: true,
    },
    {
        title: 'Support for student',
        dataIndex: 'support_for_student',
        key: 'support_for_student',
        render: (support_for_student) => <ColumnSupportForStudent support_for_student={support_for_student}/>,
        filters: supportForStudents.map(item => { return ({ 'text': item.label, value: item.value }) })
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
    },
    {
        title: 'Updated',
        dataIndex: 'updateTimestamp',
        key: 'updateTimestamp',
        sorter: true,
        render: (timestamp) => (timestamp ? 
        <>
                <span className='nowrap' key='day'>{timestamp.substring(0, 10)}</span> 
                <span className='nowrap' key='time'>{timestamp.substring(11, 19)}</span>
        </> 
        : ''),
        hidden: process.env.NODE_ENV !== 'development'
    },
    {
        title: 'Platform id',
        dataIndex: 'platform_id',
        key: 'platform_id',
        sorter: true,
    },
];