import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
// import counterReducer from './counterSlice'
// import infrastrukturaPagingReducer from './InfrastrukturaPagingSlice'
// import { pracownicyPwrPageApi } from './PracownicyPwrPage'
import { pracownicyPwrApi  } from './PracownicyPwrApi'
// import pracownicyPagingReducer from './PracownicyPagingSlice'
// import { jednostkiPwrApi } from './JednostkiPwrApi'
import { userApi, userPagingReducer, loggedUserReducer } from './UserApi'
import { studentTalentRegistrationApi } from './StudentTalentRegistrationApi'
import studentsPagingReducer from './StudentTalentPagingSlice'

export const store = configureStore({
    reducer: {
        // counter: counterReducer,
        // infrastrukturaPaging: infrastrukturaPagingReducer,
        // pracownicyPaging: pracownicyPagingReducer,

        [userApi.reducerPath]: userApi.reducer,
        [studentTalentRegistrationApi.reducerPath]: studentTalentRegistrationApi.reducer,
        studentsPaging: studentsPagingReducer,
        userPaging: userPagingReducer,
        loggedUser: loggedUserReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            // .concat(pracownicyPwrPageApi.middleware)
            .concat(pracownicyPwrApi.middleware)
            // .concat(jednostkiPwrApi.middleware)
            .concat(userApi.middleware)
            .concat(studentTalentRegistrationApi.middleware)
})
setupListeners(store.dispatch)
