import { userService } from '../components/user/user.service.js';

export const authHeader = () => {
    const token = authToken();
    const isLoggedIn = !!token;
    if (isLoggedIn) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

function authToken() {
    return userService.loggedUser()?.token;
}