import logo from '../logo.svg';
import { Link } from 'react-router-dom'

const HomePage = () => (
    <div>
        <header className="HomePage">
            <div className='HomePageStrip'></div>
            <div className='HomePageTitle'>
                <div className='AppHeader-title'><a href='/lecturer'>Talent Hunter Space</a></div>
            </div>
            <div className='HomePageDescription'>
                <p>The goal of the Talent Hunter Space is to support the process of identifying students 
                    with high potential to become Deep Tech talent. It is an IT solution, 
                    composed of the Internet Service and add-on to the known & wildly used e-learning platform Moodle 
                    the purpose of which is to provide academic teachers and other actors with the possibility to indicate 
                    a student with high potential. It allows to scout talents that were supported in various activiti</p>
            </div>
            <div className='HomePageStrip'></div> 
            <div className='HomePage-footer'>
                <div>
                    {/* <a href='/'><img src="logo_eiit.png" className="" alt="logo EITT" /></a>
                    <a href='/lecturer'><img src="logo_eu.png" alt="logo EU" /></a> */}
                    <a href='/lecturer'><img src="/HEI-A5-Watermark-Lockup-Raw.png" height="150" alt="logo EU" /></a>
                </div>
                <div className="logo_deetechtive">
                    <a href='/register'>
                        <img src="/logo_deetechtive_horizontal-color-medium.png" alt="logo deetechtive project" height="100" width="550"/>
                    </a>
                </div>
            </div>
    
            {/* <img src="zrzut_ekranu_deetechtive_2023-12-13_092405.png" className="App-logo" alt="logo" /> */}
        </header>
    </div>
)

export default HomePage