import React from "react";
import { useSelector } from 'react-redux'
//import { Route } from "react-router-dom";
//import { AuthConsumer } from "../providers/authProvider";
import { userService } from './user/user.service.js';
import LoginForm from './LoginForm.js'
import MainContent from '../sth/MainContent.js'


export const PrivateRoute = ({ setLoggedUser, children }) => {
    console.log('PrivateRoute userService.isAuthenticated()', userService.isAuthenticated())
    if (userService.isAuthenticated()){
        return (
            <>
                {children}
            </>
        )
    } else {
        return (
            <MainContent>
            <LoginForm setLoggedUser={setLoggedUser} />
            </MainContent>
        )
    }
}

export const PrivateRouteWithoutLoginMonit = ({ setLoggedUser, children }) => {
    if (userService.isAuthenticated()) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return (
            null
        )
    }
}

// export const PrivateRoute = ({ component, ...rest }) => {
//     const renderFn = Component => props => (
//         <AuthConsumer>
//             {({ isAuthenticated, signinRedirect }) => {
//                 if (!!Component && isAuthenticated()) {
//                     return <Component {...props} />;
//                 } else {
//                     signinRedirect();
//                     return <span>loading</span>;
//                 }
//             }}
//         </AuthConsumer>
//     );

//     return <Route {...rest} render={renderFn(component)} />;
// }; 

// function RouteWithSubRoutes(route) {
//     return (
//         <Route
//             path={route.path}
//             render={props => (
//                 // pass the sub-routes down to keep nesting
//                 <route.component {...props} routes={route.routes} />
//             )}
//         />
//     );
// }
