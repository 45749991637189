import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    page: 0,
    totalCount: -1,
    searchRequest: {},
}

export const studentsPagingSlice = createSlice({
    name: 'infrastrukturaPaging',
    initialState,
    reducers: {
        incrementPage: (state) => {
            state.page += 1
        },
        decrementPage: (state) => {
            state.page -= 1
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload
        },
        setSearchEmail: (state, action) => {
            state.searchRequest.email = action.payload
        },
        setSearchRequest: (state, action) => {
            state.searchRequest = Object.assign({}, state.searchRequest, action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { incrementPage, decrementPage, setPage, setTotalCount, 
    setSearchEmail, setSearchRequest } = studentsPagingSlice.actions

export default studentsPagingSlice.reducer