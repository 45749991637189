import React from 'react';
import { redirect, useHistory } from "react-router-dom";
import { Alert, Button, Checkbox, Form, Input, message } from 'antd';
import { DataProvider } from '../../sth/DataProvider'
import { useGetUserQuery, useSaveUserMutation } from '../../redux/UserApi'
import { userService } from './user.service.js';
import _ from 'lodash';

const { TextArea } = Input;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};


const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

export const UserEdit = (props) => {
    const history = useHistory();
    const userId = _.get(props, 'match.params.userId', 0)
    console.log('UserEdit userId', userId)
    const [saveUser, saveUserResult] = useSaveUserMutation(userId)

    const user = useGetUserQuery(userId)
    const { data, error, isLoading } = user
    console.log('UserEdit saveUserResult', saveUserResult)
    const formData = { ...data }
    if (formData && formData.authorities) {
        formData.admin = formData.authorities.map(auth => auth.name).includes("ROLE_ADMIN")
    }
    console.log('UserEdit error', error)
    const saveOk = saveUserResult.status === 'fulfilled'
    if (saveOk) {
        message.success('User saved');
        history.push('/user')
    }

    const onFinish = (values) => {
        const authorities = [{ "name": "ROLE_USER" },]
        if (values.admin) authorities.push({ "name": "ROLE_ADMIN" })
        const toSend = {
            id: userId, authorities, ...values
        }
        console.log('To send:', toSend);
        saveUser(toSend)
        // toSend.support_for_student = JSON.stringify(toSend.support_for_student)
        // console.log('To send:', toSend);
        // DataProvider.sendToServer(toSend,
        //     fromServer => {
        //         console.log('fromServer:', fromServer);
        //         history.push('/user')
        //     }, error => {
        //         console.log('server error', error)
        //     })
    };
    return (
        <div className='register'>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
                ) : formData ? (<EditForm data={formData} onFinish={onFinish} saveUserResult={saveUserResult} />
            ) : null}
        </div>
    )
};

const EditForm = ({ data, onFinish, saveUserResult }) => {
    const saveError = saveUserResult.isError
    let saveErrorMessage = ''
    if (saveError) {
        saveErrorMessage = _.get(saveUserResult, 'error.data.violations', _.get(saveUserResult, 'error.data.message', ''))
        if(Array.isArray(saveErrorMessage)) {
            saveErrorMessage = saveErrorMessage.reduce((a,b) => a+'; '+b)
        }
    }

    return (
        <Form name="basic" layout="horizontal" labelCol={{ span: 8, }}
            wrapperCol={{ span: 12, }}
            style={{ width: 1000, }}
            initialValues={{ ...data, remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className='register-title'>Edit user</div>
            
            {saveError && <Form.Item wrapperCol={{ offset: 6, span: 14, }}><Alert message={saveErrorMessage} type="error" /></Form.Item>}
            
            <Form.Item label="login" name="login" >
                <Input />
            </Form.Item>
            <Form.Item label="Name" name="firstName" >
                <Input />
            </Form.Item>
            <Form.Item label="Surname" name="lastName"
                rules={[{ message: 'Please input user surname!', },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="Email" name="email" disabled={true}
                rules={[{ required: true, message: 'Please input user email!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Activated" name="activated" valuePropName="checked">
                <Checkbox>Activated</Checkbox>
            </Form.Item>
            <Form.Item label="Roles" name="admin" valuePropName="checked">
                <Checkbox>admin</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 3, span: 14, }}>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button type="link" href="/user" htmlType="button">
                    Cancel
                </Button>
            </Form.Item>

        </Form>
    )
}
export default UserEdit;
