import React from 'react';
import { redirect, useHistory } from "react-router-dom";
import { Alert, Button, Checkbox, Form, Input, Select } from 'antd';
import { DataProvider } from '../../sth/DataProvider'
import { useGetUserQuery, useRegisterUserMutation } from '../../redux/UserApi'
import { userService } from './user.service.js';
import _ from 'lodash';

const { TextArea } = Input;

const handleChange = (value) => {
    console.log(`selected ${value}`);
};


const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

export const UserRegister = (props) => {
    const history = useHistory();

    const [saveUser, saveUserResult] = useRegisterUserMutation()

    console.log('UserRegister saveUserResult', saveUserResult)
    const formData = {  }
    const saveOk = saveUserResult.isSuccess
    if (saveOk) {
        history.push('/user')
    }
    const saveError = saveUserResult.isError
    let saveErrorMessage = 'Error'
    if (saveError) {
        saveErrorMessage = _.get(saveUserResult, 'error.data.violations', _.get(saveUserResult, 'error.data.message', saveErrorMessage))
        if (Array.isArray(saveErrorMessage)) {
            saveErrorMessage = saveErrorMessage.reduce((a, b) => a + '; ' + b)
        }
    }

    const onFinish = (values) => {
        const authorities = [{ "name": "ROLE_USER" },]
        if (values.admin) authorities.push({ "name": "ROLE_ADMIN" })
        const toSend = {
            authorities, ...values
        }
        console.log('To send:', toSend);
        saveUser(toSend)
        // toSend.support_for_student = JSON.stringify(toSend.support_for_student)
        // console.log('To send:', toSend);
        // DataProvider.sendToServer(toSend,
        //     fromServer => {
        //         console.log('fromServer:', fromServer);
        //         history.push('/user')
        //     }, error => {
        //         console.log('server error', error)
        //     })
    };
    return (
        <div className='register'>
            <Form name="basic" layout="horizontal" labelCol={{ span: 8, }}
                wrapperCol={{ span: 12, }}
                style={{ width: 1000, }}
                initialValues={{ ...formData, remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <input autocomplete="false" name="hidden" type="text" style={{ display: "none" }}></input>
                <div className='register-title'>Register new user account</div>

                {saveError && <Form.Item wrapperCol={{ offset: 6, span: 14, }}><Alert message={saveErrorMessage} type="error" /></Form.Item>}

                <Form.Item label="Email" name="email" disabled={true}
                    rules={[{ required: true, message: 'Please input user email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Login" name="login" autocomplete="false"
                    rules={[{ required: true, message: 'Please input user login!' }]}>
                    <Input autocomplete="off" />
                </Form.Item>
                <Form.Item label="Password" name="password"
                    rules={[{ required: true, message: 'Please input your password!', },]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item label="Name" name="firstName" >
                    <Input />
                </Form.Item>
                <Form.Item label="Surname" name="lastName"
                    rules={[{ message: 'Please input user surname!', },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Activated" name="activated" valuePropName="checked">
                    <Checkbox>Activated</Checkbox>
                </Form.Item>
                <Form.Item label="Authorities" name="admin" valuePropName="checked">
                    <Checkbox>Admin</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 3, span: 14, }}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button type="link" href="/user" htmlType="button">
                        Cancel
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
};

export default UserRegister;
