import React, { useState } from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { Icon, Pagination, Form, Table, Button, Container, Input } from 'semantic-ui-react'
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux'
//import { useGetPracownicyPwrPageAllQuery } from '../../redux/PracownicyPwrPage'
import { useGetPracownicyPwrSearchQuery } from '../../redux/PracownicyPwrApi'
import { setPage } from '../../redux/PracownicyPagingSlice'
//import JednostkaSearch from '../jednostki/JednostkaSearch'

const PracownicyList = () => {
    const page = -1//useSelector((state) => state.infrastrukturaPaging.page)
    const totalCount = -1//useSelector((state) => state.infrastrukturaPaging.totalCount)

    const [searchRequest, setSearchRequest] = useState({})
    const [sortColumn, setSortColumn] = useState('name')
    const [sortDesc, setSortDesc] = useState(false)
    const sort = sortColumn + (sortDesc ? ',desc' : '')
    //const qResult = useGetPracownicyPwrPageAllQuery()
    console.log('PracownicyList searchRequest', searchRequest)
    //const { data, error, isLoading } = useGetPracownicyPwrPageAllQuery()
    //const { data, error, isLoading } = useGetPracownicyPwrQuery()
    const pracownicyPageSearch = useGetPracownicyPwrSearchQuery({ page, sort, searchRequest })
    //console.log('PracownicyList pracownicyPageSearch', pracownicyPageSearch)
    const listaPracownikow = _.get(pracownicyPageSearch, 'data.content', [])
    const { error, isLoading } = pracownicyPageSearch

    const dispatch = useDispatch()

    const pageSize = 20
    let totalPages = 0
    if (totalCount > 0) {
        totalPages = Math.floor(totalCount / pageSize)
    }

    const onPageChange = (event, data) => {
        dispatch(setPage(data.activePage - 1))
    }

    return (
        <div className=''>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
            ) : listaPracownikow ? (
                <div className='projectList'>
                    <SearchPanel setSearchRequest={setSearchRequest} />
                    <TabelaPracownikow listaPracownikow={listaPracownikow}
                        sortable={{ sortColumn, setSortColumn, sortDesc, setSortDesc }}
                        pageable={{ page, totalCount, totalPages, onPageChange }} />
                </div>
            ) : null}
        </div>
    )
}

const SearchPanel = ({ setSearchRequest }) => {
    const [searchText, setSearchText] = useState('')
    const handleSearch = e => {
        if (e.key === 'Enter' || e.type === 'click') {
            setSearchRequest({ searchText: searchText.trim() }) //props.onSearch(searchText.trim(), e);
        }
    }
    return (
        <div>
            {
                process.env.NODE_ENV == 'development'
                &&
                <div className="searchDiv">
                    <Input autoFocus placeholder="Szukaj..."
                        icon={{ name: 'search', link: true, onClick: handleSearch }} //() => setSearchRequest({ searchText: searchText })
                        onKeyUp={handleSearch}
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                    <Form.Field key="jed">
                        {/* <label>Jednostka</label> */}
                        {/* {jednostkaName} {_.get(pracownik, 'jednostkaPwr.symbol', '')} */}
                        {/* <JednostkaSearch
                            placeholder='wybierz jednostkę...'
                            //defaultValue={jednostkaName}
                            //insertValueOnSelect={true}
                            autoFocus={false}
                        //onJednostkaChange={handleChangeJednostka}
                        //readOnly={readOnly}
                        //onProjectPartnerAdd={(e, data) => onInfrastructureChange({ organizationId: data.id, organizationName: data.title })}
                        //onSearchClear={() => onInfrastructureChange({ organizationId: null, organizationName: '' })}
                        /> */}
                    </Form.Field>
                </div>

                //     <Form size='tiny'>
                //     {/* <Container textAlign='center' as='h5' fluid className='search-header'>
                //         <Button primary icon style={{ margin: '0 15px' }}
                //             onClick={searchInfrastructure} disabled={!searchFieldsChanged}>
                //             <Icon name='search' /> Wyszukaj infrastrukturę
                //         </Button>
                //     </Container> */}
                //     <Form.Group>
                //     <Form.Input
                //         label='Szukane słowo'
                //         placeholder='Free search'
                //         name='freeSearch'
                //         //value={infrastructureSearchFields.freeSearch || ''}
                //         //onChange={(e, data) => utils.handleInputChange(e, onInfrastructureSearchFieldsChange)}
                //     />
                //         <Button primary icon size='mini' style={{ margin: '0 15px' }}
                //         //</Form>onClick={searchInfrastructure} disabled={!searchFieldsChanged}
                //         >
                //         <Icon name='search' /> Wyszukaj pracownika
                //     </Button>
                //     </Form.Group>
                // </Form>
            }
        </div>
    )
}

const TabelaPracownikow = ({ listaPracownikow, pageable, sortable }) => {
    const { page, totalCount, totalPages, onPageChange } = pageable
    const { sortColumn, setSortColumn, sortDesc, setSortDesc } = sortable
    const onSort = (column) => {
        if (sortColumn === column) setSortDesc(!sortDesc)
        else {
            setSortColumn(column)
            setSortDesc(false)
        }
    }
    const isSortedByColumn = column => {
        if (sortColumn != column) return null
        if (sortDesc) return 'descending'
        else return 'ascending'
    }
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell onClick={evt => onSort('name')} sorted={isSortedByColumn('name')}>
                        Pracownik
                    </Table.HeaderCell>
                    <Table.HeaderCell onClick={evt => onSort('jednostkaNad')} sorted={isSortedByColumn('jednostkaNad')}>
                        Jednostka nadrzędna
                    </Table.HeaderCell>
                    <Table.HeaderCell onClick={evt => onSort('jednostkaPwr')} sorted={isSortedByColumn('jednostkaPwr')}>
                        Jednostka
                    </Table.HeaderCell>
                    <Table.HeaderCell>E-mail</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    listaPracownikow.map(pracownik =>
                        <Table.Row key={pracownik.id}>
                            <Table.Cell>
                                {/* <Button content='lista'
                                    primary as={Link} to={`/pracownicy/${pracownik.id}`} >
                                    {pracownik.name}
                                </Button> */}
                                <Link to={`/pracownicy/${pracownik.id}`}
                                    title='Preview'>
                                    {pracownik.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>{pracownik.jednostkaNad}</Table.Cell>
                            <Table.Cell>{pracownik?.jednostkaPwr?.name}</Table.Cell>
                            <Table.Cell>{pracownik.email}</Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                            activePage={page + 1}
                        />
                        <div>
                            Rekordów {totalCount}
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    )
}
export default PracownicyList