import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash';
import { authHeader } from './auth'

const initialState = {
    page: 0,
    totalCount: -1,
    searchRequest: {},
}

export const userPagingSlice = createSlice({
    name: 'userPaging',
    initialState,
    reducers: {
        incrementPage: (state) => {
            state.page += 1
        },
        decrementPage: (state) => {
            state.page -= 1
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload
        },
        setSearchEmail: (state, action) => {
            state.searchRequest.email = action.payload
        },
        setSearchRequest: (state, action) => {
            state.searchRequest = Object.assign({}, state.searchRequest, action.payload);
        },
    },
})
// Action creators are generated for each case reducer function
export const { incrementPage, decrementPage, setPage, setTotalCount,
    setSearchEmail, setSearchRequest } = userPagingSlice.actions
export const userPagingReducer = userPagingSlice.reducer

export const isLoggedUserAdmin = (state) => {
    const authorities = _.get(state, 'loggedUser.loggedUser.authorities', null)
    if (Array.isArray(authorities)) {
        return authorities.find(auth => auth.name === 'ROLE_ADMIN') !== undefined
    }
    return false
}
export const isLoggedUserRecordOwner = (state, email) => {
    const loggedUserEmail = _.get(state, 'loggedUser.loggedUser.email', null)
    return email === loggedUserEmail
}

export const loggedUserSlice = createSlice({
    name: 'loggedUser',
    initialState: { loggedUser: null },
    reducers: {
        setLoggedUser: (state, action) => {
            console.log('loggedUserSlice', action)
            state.loggedUser = action.payload
        },
    },
})
// Action creators are generated for each case reducer function
export const { setLoggedUser } = loggedUserSlice.actions
export const loggedUserReducer = loggedUserSlice.reducer


export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['Users'],
    baseQuery: fetchBaseQuery({ baseUrl: '/api/rest/user' }),
    endpoints: (builder) => ({
        getUser: builder.query({
            query: (id) => `/${id}`,
        }),
        getUserLogin: builder.query({
            query: ({ loginForm }) => {
                return ({
                    url: `/login`,
                    method: 'POST',
                    headers: authHeader(),
                    body: loginForm,
                })
            },
            onCacheEntryAdded(id, params) {
                const { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry,
                    updateCachedData } = params
                console.log('getUserLogin onCacheEntryAdded params', params)
                cacheDataLoaded.then(() => {
                    const cacheEntry = getCacheEntry()
                    const email = _.get(cacheEntry, 'email', null)
                    if (email) dispatch(setLoggedUser(cacheEntry))
                    // console.log('onCacheEntryAdded totalElements', totalElements)
                    // console.log('onCacheEntryAdded getCacheEntry', cacheEntry)
                })
            },
        }),
        getUsersSearch: builder.query({
            query: ({ page = -1, size = 20, sort = 'updateTimestamp,desc', searchRequest }) => {
                return({
                url: `/search?page=${page}&sort=${sort}&size=${size}`,
                method: 'POST',
                headers: authHeader(),
                body: searchRequest,
            })},
            providesTags: (result, error, id) => [{ type: 'Users', id: 'LIST' }],
            onCacheEntryAdded(id, params) {
                const { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry,
                    updateCachedData } = params
                // console.log('onCacheEntryAdded params', params)
                cacheDataLoaded.then(() => {
                    const cacheEntry = getCacheEntry()
                    const totalElements = _.get(cacheEntry, 'data.totalElements', -1)
                    dispatch(setTotalCount(totalElements))
                    // console.log('onCacheEntryAdded totalElements', totalElements)
                    // console.log('onCacheEntryAdded getCacheEntry', cacheEntry)
                })
            },
        }),
        saveUser: builder.mutation({
            query: (payload) => ({
                url: `/save`,
                method: 'POST',
                headers: authHeader(),
                body: payload,
            }),
            invalidatesTags: ['Users'],
        }),
        registerUser: builder.mutation({
            query: (payload) => ({
                url: `/register`,
                method: 'POST',
                headers: authHeader(),
                body: payload,
            }),
            invalidatesTags: ['Users'],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
                headers: authHeader(),
            }),
            invalidatesTags: ['Users'],
        }),

    }),
})

export default userApi
export const { useGetUsersSearchQuery, useGetUserQuery, useGetUserLoginQuery,
    useSaveUserMutation, useRegisterUserMutation, useDeleteUserMutation } = userApi
