export const consts = {
    ENDPOINT_URL: '/api/rest/student-talent-registration',
}

export const DataProvider = {

    sendToServer: (additionalFields, promiseHandler, errorHandler) => {
        const forSend = Object.assign({}, { ...additionalFields })
        const forSendJson = JSON.stringify(forSend)

        fetch(consts.ENDPOINT_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: forSendJson
        })
            .then(response => {
                if (response.status != 200) {
                    return Promise.reject();
                }
                return response.json()
            })
            .then(json => {
                if (json.is_request_successful === false) {
                    const error_message = json.error_message
                    const errorCause = json.cause
                    return Promise.reject({ error_message, errorCause })
                }
                const fromServer = json

                promiseHandler(fromServer)
            })
            .catch(error => errorHandler(error))
    },

    search: async (filter) => {
        const forSend = Object.assign({}, { ...filter })
        const forSendJson = JSON.stringify(forSend)
        const url = consts.ENDPOINT_URL + '/search'
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: forSendJson
        })
        return await response.json();
    },

    fetchRodzajLista: async (filter) => {
        const forSend = Object.assign({}, { ...filter })
        const doWyslaniaJson = JSON.stringify(forSend)
        const jsonName = consts.ENDPOINT_URL + '?action=pobierz_rodzaj_lista_json'
        const response = await fetch(jsonName);
        return response.json();
    }

}