import React, { useEffect, useState } from "react";
import { redirect, useHistory } from "react-router-dom";
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { useGetUserLoginQuery, setLoggedUser } from '../redux/UserApi'
import { userService } from './user/user.service.js';
import _ from 'lodash';


const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const sendForm = async ({ username, password }) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const response = await fetch("/api/rest/user/login", {
        method: "POST",
        body: formData,
    });
    console.log('response', response);
    //await response.json()
}

const LoginForm = ({  }) => {
    const [error, setError] = useState('');
    const loggedUser = useSelector((state) => _.get(state, 'loggedUser.loggedUser', null))
    const dispatch = useDispatch()
    const history = useHistory();

    const useOnFinish = (values) => {
        console.log('LoginForm onFinish:', values);
        //const userSearch = useGetUserLoginQuery({ login: values.username, password: values.password })
        //send({ login: values.username, password: values.password })
        const loginSuccess = logged => {
            setError(null)
            //setLoggedUser(logged)
            dispatch(setLoggedUser(logged))
        }
        userService.login(values.username, values.password, loginSuccess, err => setError(err))
    };
    const send = async (payload) => {
        const response = await fetch("/api/rest/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(payload),
        });
        console.log('response', response);
        const json = await response.json()
        console.log('json', json);
        setError(_.get(json, 'error', null))
        const isEntity = _.get(json, 'email', null) !== null
        if (isEntity) {
            console.log('setLoggedUser', json);
            dispatch(setLoggedUser(json))
            history.push('/lecturer')
        } 
    }

    return(
        <div className="login_form">
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                
            }}
                onFinish={useOnFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
                {/* <Form.Item>
                    {loggedUser && <Alert message={loggedUser.email} type="success" />}
                </Form.Item> */}
                <Form.Item>
                    {error && <Alert message={error} type="error" />}
                </Form.Item>
            <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Log in
                </Button>
            </Form.Item>
        </Form> 
        </div>
   )
}

export default LoginForm