import { Link } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import _ from 'lodash';
import { userService } from '../components/user/user.service.js';
import { useGetUserLoginQuery, setLoggedUser } from '../redux/UserApi'


const MainContent = ({ children }) => {
    const loggedUser = userService.loggedUser()
    return (
        <div className='mainContent'>
            <AppHeader />
            <div className='header_menu'>
                <div className='header_menu_item'>{loggedUser && <AppMenu loggedUser={loggedUser} setLoggedUser={setLoggedUser} />}</div>
            </div>
            {children}
            {/* <AppFooter /> */}
        </div>
    )
}

export default MainContent

const AppHeader = () => {
    const loggedUserRdx = useSelector((state) => _.get(state, 'loggedUser.loggedUser.email', 'null'), shallowEqual)
    return (
        <div className='AppHeader'>
            <div data-usr={loggedUserRdx}>
                {/* <a href='/'><img src="/HEI-Watermark-Lockup-Raw Materials.svg" className="" alt="logo EITT" /></a> */}
                <a href='/'><img src="/HEI-A5-Watermark-Lockup-Raw.png" height="100" alt="logo EU" /></a>
            </div>
            <div className='AppHeader-title'>
                <a href='/'>Talent Hunter Space</a>
            </div>
            <div className="logo_deetechtive">
                <a href='/'>
                <img src="/logo_deetechtive.png" alt="logo deetechtive project" />
                </a>
                </div>
        </div>
    )
}

const AppMenu = ({ loggedUser }) => {
    const loggedUserRdx = useSelector((state) => _.get(state, 'loggedUser.loggedUser', null), shallowEqual)
    const dispatch = useDispatch()
    const onClick = (e) => {
        console.log('click ', e);
        //setCurrent(e.key);
        if (e.key === 'logout') {
            userService.logout()
            dispatch(setLoggedUser({}))
        }
    };
    const itemsUser = [{
        label: 'Logged as ' + loggedUser.email,
        key: 'app',
        icon: <AppstoreOutlined />,
        children: [
            {
                label: 'Log out',
                key: 'logout',
            },
            {
                label: 'Change password',
                key: 'changePassword',
                disabled: true,
            },
        ],
    }]
    if (userService.isAdmin()) {
        itemsUser.push({
            label: 'Administration',
            key: 'administration',
            icon: <SettingOutlined />,
            children: [
                {
                    label: (
                        <a href="/user" >
                            Users
                        </a>
                    ),
                    key: 'user-list',
                },
                {
                    label: (
                        <a href="/user/register" >
                            Register new user
                        </a>
                    ),
                    key: 'user-register',
                    //disabled: process.env.NODE_ENV !== 'development',
                },
                {
                    label: (
                        <a href="/manager" target="_blank" >
                            Tomcat manager
                        </a>
                    ),
                    key: 'tomcat-manager',
                },
                {
                    label: (
                        <a href="https://ideation.pwr.edu.pl/development/ths_download" target="_blank" rel="noreferrer" >
                            Download last version
                        </a>
                    ),
                    key: 'download-app',
                },
            ],
        })
    }
    return(
        <>
            <Menu style={{ minWidth: 0, flex: "auto", alignItems: "right" }} onClick={onClick} mode="horizontal" items={itemsUser} 
            />
        </>
    )
}

const items = [
    {
        label: 'Navigation One',
        key: 'mail',
        icon: <MailOutlined />,
    },
    {
        label: 'Navigation Two',
        key: 'app',
        icon: <AppstoreOutlined />,
        disabled: true,
    },
    {
        label: 'Navigation Three - Submenu',
        key: 'SubMenu',
        icon: <SettingOutlined />,
        children: [
            {
                type: 'group',
                label: 'Item 1',
                children: [
                    {
                        label: 'Option 1',
                        key: 'setting:1',
                    },
                    {
                        label: 'Option 2',
                        key: 'setting:2',
                    },
                ],
            },
            {
                type: 'group',
                label: 'Item 2',
                children: [
                    {
                        label: 'Option 3',
                        key: 'setting:3',
                    },
                    {
                        label: 'Option 4',
                        key: 'setting:4',
                    },
                ],
            },
        ],
    },
    {
        label: (
            <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                Navigation Four - Link
            </a>
        ),
        key: 'alipay',
    },
];