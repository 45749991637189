import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import MainContent from '../../sth/MainContent'
import UserList from './UserList'
import UserEdit from './UserEdit'
import UserRegister from './UserRegister'
//import PracownicyEdit from './PracownicyEdit'

const UserRoute = (props) => (
    <Switch>
        <Route path="/user/register">
            <MainContent><UserRegister /></MainContent>
        </Route>
        <Route path="/user/:userId" render={props =>
           <MainContent><UserEdit {...props} /></MainContent>
        } />
        <Route path="/user">
            <MainContent><UserList /></MainContent>
        </Route>


        {/* <Route path="/infrastructures/:infrastructureId" render={props =>
            <InfrastructureCreateForm {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Infrastruktura</span>} />
        } /> */}
    </Switch>
)

export default UserRoute
